// React core.
import React, { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';

import { FirebaseAuthProvider, useFirebaseAuth } from "./FirebaseAuthContext";
import { useQuery } from 'react-query';
import request, { gql } from 'graphql-request';


// Components
import {
  Collapse, Row, Container, Col, Card, CardBody, CardHeader, Table
} from 'reactstrap';
import Header from './Nav';



function SpecificUserPage(args) {
  // const [isOpen, setIsOpen] = useState(false);
  let { id } = useParams();

  // const toggle = () => setIsOpen(!isOpen);

  const GRAPHQL_ENDPOINT = "https://api.completeaviation.co.uk/graphql"
  // const GRAPHQL_ENDPOINT = "http://127.0.0.1:8080/graphql"

  const getSpecificUserQuery = `
  query getSpecificUserQuery($id: String!) {
    account(id: $id) {
      id
      email
      email_verified
      role
      created_at
      updated_at
      display_name
      first_name
      surname
      public
      roster {
        roster_system {
          id
          label
        }
        active
        deleted
        last_updated
        last_checked
        meta
      }
      employments {
        active
        position
        regulator
        created_at
        updated_at
        employee_number
        employee_code
        aircraft_name
        deleted
        airline {
          id
          icao_code
          iata_code
          name
        }
      }
      base {
        id
        icao_code
        iata_code
        name
        timezone
        country_alpha3
      }
      subscription {
        state
        last_checked
        id
        external_id
        expected_expiration
        created_at
        purchases_data
      }
      logbook {
       id
        changes(take:1, orderBy: {increment_id: desc}) {
          id
          entry_id
          type
          data
          created_at
        }
      }
    }
  }
  `;
  const user = useFirebaseAuth();

  const queryVariables = { id };

  let tokenManager = {}
  if (user) {
    console.log(user.toJSON())
    tokenManager = user.toJSON().stsTokenManager
  } else {
    return "Loading";
  }
  console.log(tokenManager)
  const headers = { Authorization: `Bearer ${tokenManager.accessToken}` }

  const getSpecificUser = () => request(GRAPHQL_ENDPOINT,
    getSpecificUserQuery,
    queryVariables,
    headers
  );

  const { isLoading, error, data, refetch } = useQuery("getSpecificUserQuery", getSpecificUser);

  if (isLoading) return "Loading";

  if (error) return error.message;
  console.log(data)

  const {
    email,
    email_verified,
    role,
    created_at,
    updated_at,
    display_name,
    first_name,
    surname,
    roster,
    employments,
    base,
    subscription,
    logbook,
  } = data.account

  // const email = 'johndoe@example.com';
  // const displayName = 'John Doe';
  const airline = 'Delta Airlines';
  const crewCode = 'XYZ123';
  const staffNo = '12893';
  const rank = 'Captain';
  const regulator = 'FAA';
  const aircraftName = 'B737 Group';

  const transactions = [
    {
      id: '001',
      type: 'Purchase',
      amount: '$50.00',
      date: '2022-01-01',
    },
    {
      id: '002',
      type: 'Refund',
      amount: '$20.00',
      date: '2022-01-05',
    },
    {
      id: '003',
      type: 'Purchase',
      amount: '$30.00',
      date: '2022-01-10',
    },
  ];
  // const planName = subscription.purchases_data ? subscription.purchases_data.map () : '-'


  const recentActivity = [
    {
      id: '001',
      message: 'You have a new message from John Smith',
      date: '2022-02-12',
    },
    {
      id: '002',
      message: 'Your account information has been updated',
      date: '2022-02-10',
    },
    {
      id: '003',
      message: 'You have a new notification from the admin',
      date: '2022-02-08',
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <h2>User Info</h2>
          <Table dark>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>ID</th>
                <td>{id}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{email}</td>
              </tr>
              <tr>
                <th>Display Name</th>
                <td>{display_name}</td>
              </tr>
              <tr>
                <th>First Name</th>
                <td>{first_name}</td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>{surname}</td>
              </tr>
            </tbody>
          </Table>
          <h3>Employment Info</h3>
          {employments.map(employment => {
            return <Table dark>
              <tbody>
                <tr>
                  <th>Airline</th>
                  <td>{employment.airline ? employment.airline.name : '-'}</td>
                </tr>
                <tr>
                  <th>Crew Code</th>
                  <td>{employment.employee_code ? employment.employee_code : '-'}</td>
                </tr>
                <tr>
                  <th>Staff No.</th>
                  <td>{employment.employee_number ? employment.employee_number : '-'}</td>
                </tr>
                <tr>
                  <th>Rank</th>
                  <td>{employment.position}</td>
                </tr>
                <tr>
                  <th>Regulator</th>
                  <td>{employment.regulator}</td>
                </tr>
                <tr>
                  <th>Aricraft Type</th>
                  <td>{employment.aircraft_name}</td>
                </tr>
              </tbody>
            </Table>
          })}


          <h2>Subscription Status</h2>
          {subscription ? <Table dark>
            <tbody> <tr>
              <th>ID</th>
              <td>{subscription.id}</td>
            </tr>
              <tr>
                <th>State</th>
                <td>{subscription.state}</td>
              </tr>
              <tr>
                <th>Plan Name</th>
                <td>{subscription.active_pna_name}</td>
              </tr>
              <tr>
                <th>External ID</th>
                <td>{subscription.external_id}</td>
              </tr>
              <tr>
                <th>Billing Period</th>
                <td></td>
              </tr>
              <tr>
                <th>Last Checked</th>
                <td>{subscription.last_checked}</td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{subscription.created_at}</td>
              </tr>
              <tr>
                <th>Purchase Data</th>
                <td><textarea rows="4" cols="500">{JSON.stringify(subscription.purchases_data)}</textarea></td>
              </tr></tbody>

          </Table> : null}


        </Col>
      </Row>
    </Container >
  );
};




export default SpecificUserPage;