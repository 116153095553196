
// React core.
import React, { useState } from 'react';

// Components
import {
  Collapse, Row, Container, Card, CardBody, CardTitle, Badge

} from 'reactstrap';

import {
  Link,
} from 'react-router-dom';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
// import * as gql from 'gql-query-builder';
import { useQuery } from 'react-query';
import request, { gql } from 'graphql-request';

import { FirebaseAuthProvider, useFirebaseAuth } from "./FirebaseAuthContext";

function FindUsersPage(args) {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);
  const fields = [
    { name: 'id', title: 'ID' },
    { name: 'email', title: 'email' },
    { name: 'name', title: 'Display Name' },
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'airlineName', title: 'Airline' },
    { name: 'employeeCode', title: 'Crew Code/Staff No.' },
    { name: 'subscriptionState', title: 'Sub Status' },
    { name: 'subscriptionName', title: 'Sub Name' },
  ];

  const Cell = (props) => {
    const { column, children, value } = props;
    if (column.name === 'id') {
      return <Table.Cell  {...props}><Link to={`/users/${props.value}`}>{children || value}</Link> </Table.Cell>
    }
    else if (column.name === 'employeeCode') {
      return <Table.Cell  {...props}> {children || value}/{props.value.employeeNumber ? props.value.employeeNumber : '-'} </Table.Cell>
    }
    return <Table.Cell {...props} />;
  };

  const GRAPHQL_ENDPOINT = "https://api.completeaviation.co.uk/graphql"
  // const GRAPHQL_ENDPOINT = "http://localhost/graphql"

  const getAllUsersQuery = `
  query getAllUsers {
    accounts {
      id
      email
      email_verified
      role
      created_at
      updated_at
      display_name
      first_name
      surname
      public
      employments {
        active
        position
        regulator
        created_at
        updated_at
        employee_number
        employee_code
        aircraft_name
        deleted
        airline {
          name
          id
          icao_code
          iata_code
        }
      }
      base {
        id
        icao_code
        iata_code
        name
        timezone
        country_alpha3
      }
      subscription {
        active_plan_name
        state
        last_checked
        id
        external_id
        expected_expiration
        created_at
      }
    }
  }
  `;
  const user = useFirebaseAuth();

  const queryVariables = {};

  let tokenManager = {}
  if (user) {
    console.log(user.toJSON())
    tokenManager = user.toJSON().stsTokenManager
  } else {
    return "Loading";
  }
  console.log(tokenManager)
  const headers = { Authorization: `Bearer ${tokenManager.accessToken}` }

  const getAllUsers = () => request(GRAPHQL_ENDPOINT,
    getAllUsersQuery,
    queryVariables,
    headers
  );


  const { isLoading, error, data, refetch } = useQuery("getAllUsers", getAllUsers);

  if (isLoading) return "Loading";

  if (error) return error.message;
  
  console.log(data)

  const rows = data.accounts.map(d => {
    const airline = d.employments[0] ? d.employments[0].airline : undefined
    const { employee_number, employee_code } = d.employments[0] ? d.employments[0] : {}
    // const crewCode = d.employments[0].employee_number ? d.employments[0].employee_number : d.employments[0].employee_code
    return { id: d.id, email: d.email, name: d.display_name, firstName: d.first_name, lastName: d.surname, airlineName: airline ? airline.name : '-', employeeCode: employee_code ? employee_code : '-', employeeNumber: employee_number ? employee_number : '-', subscriptionState: d.subscription ? d.subscription.state : '-', subscriptionName: d.subscription ? d.subscription.active_plan_name : '-' }
  })

  console.log(rows)

  // Total Users
  const getTotalUsers = (accounts) => {
    return accounts.length;
  }

  // New users in the last 30 days
  const get30DayNewUsers = (accounts) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return accounts.filter(account => new Date(account.created_at) > thirtyDaysAgo).length;
  }

  // Total Subscribed Users
  const getTotalSubscribedUsers = (accounts) => {
    return accounts.filter(account => account.subscription !== null && account.subscription.state > 0).length;
  }

  const getTotalActiveSubscribedUsers = (accounts) => {
    return accounts.filter(account => account.subscription !== null && account.subscription.state > 0).length;
  }

  // New subscriptions in the last 30 days
  const get30DayActiveNewSubs = (accounts) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return accounts.filter(account => 
      account.subscription && new Date(account.subscription.created_at) > thirtyDaysAgo && account.subscription.state > 0 // filter only active still
    ).length;
  }

  const isSubscriptionActive = (subscriptionState) => {
    return subscriptionState > 0;
  }
  
  // 5. Percentage of users with active subscriptions
  const getUserPercentageWithActiveSubscription = (accounts) => {
    const activeSubUsers = accounts.filter(account => 
      account.subscription && isSubscriptionActive(account.subscription.state)
    ).length;
    return ((activeSubUsers / getTotalUsers(accounts)) * 100).toFixed(2);
  }

  // 6. Get percentages of users on different plans
  const getUserPercentageOnPlan = (accounts, planName) => {
    const usersOnPlan = accounts.filter(account => 
      account.subscription && account.subscription.active_plan_name === planName
    ).length;
    return ((usersOnPlan / getTotalUsers(accounts)) * 100).toFixed(2);
  }

  const totalUsers = getTotalUsers(data.accounts)
  const total30DayNewUsers = get30DayNewUsers(data.accounts)
  const totalSubCount = getTotalSubscribedUsers(data.accounts)
  const totalActiveSubCount = getTotalActiveSubscribedUsers(data.accounts)
  const total30DayNewSubs = get30DayActiveNewSubs(data.accounts)
  const userPercentageWithActiveSubscription = getUserPercentageWithActiveSubscription(data.accounts);
  // TODO: stopped building because plan name is somethings null (needs db clean up) & there are many plans..
  // const percentageOfUsersOnPlanPro = getUserPercentageOnPlan(data.accounts, "CA_Premium_T1_Annual")
  // const percentageOfUsersOnPlanRoster = getUserPercentageOnPlan(data.accounts, "..")
  // const percentageOfUsersOnPlanLogbook = getUserPercentageOnPlan(data.accounts, "..")
  // const percentageOfUsersOnNoPlan = 100 - (percentageOfUsersOnPlanPro + percentageOfUsersOnPlanRoster + percentageOfUsersOnPlanLogbook)

  return (

    <Container fluid className="mt-3">
      <h2>Users</h2>

      <Row>
        <div className="col-xl-3 col-lg-6">
          <Card className="bg-dark">
            <CardBody>
              <CardTitle tag="h5" className="text-muted">Total Count</CardTitle>
              <h2>{totalUsers}</h2>
              <Badge color="success">{total30DayNewUsers} (last 30 days)</Badge>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Card className="bg-dark">
            <CardBody>
              <CardTitle tag="h5" className="text-muted">Total Subs</CardTitle>
              <h2>{totalSubCount} ({userPercentageWithActiveSubscription}%)</h2>
              {totalSubCount !== totalActiveSubCount && (
                <Badge color="info">{totalActiveSubCount} active</Badge>
              )}
              <Badge color="success">{total30DayNewSubs} (last 30 days)</Badge>
            </CardBody>
          </Card>
        </div>
        {/* <div className="col-xl-3 col-lg-6">
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="text-muted">Auto Renew Count</CardTitle>
              <h2>{}</h2>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-3 col-lg-6">
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="text-muted">Sub Balance</CardTitle>
              <h2>{percentageOfUsersOnPlanPro}%</h2>
              <h2>{percentageOfUsersOnPlanRoster}%</h2>
              <h2>{percentageOfUsersOnPlanLogbook}%</h2>
              <h2>{percentageOfUsersOnNoPlan}%</h2>
            </CardBody>
          </Card>
        </div> */}
      </Row>


      <Grid
        className="mt-4"
        rows={rows}
        columns={fields}
      >
        <FilteringState defaultFilters={[]} />
        <SummaryState
          totalItems={[{ columnName: 'id', type: 'count' }]}
        />
        <IntegratedFiltering />
        <IntegratedSummary />
        <Table cellComponent={Cell} />
        <TableHeaderRow />
        <TableSummaryRow />
        <TableFilterRow />
      </Grid>
    </Container>



  );
}

export default FindUsersPage;