
// React core.
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";

// Firebase.
import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, useFirebaseAuth } from "./FirebaseAuthContext";

// Styles

import 'bootstrap/dist/css/bootstrap.min.css';
import './app.global.css';
import './firebaseui-styling.global.css'; // Import globally.


import { QueryClient, QueryClientProvider } from 'react-query';

// Components
import {
  Button,
} from 'reactstrap';
import Header from './Nav'
import PatchRyanairPage from './PatchRyanairPage'
import FindUsersPage from './ListUsersPage';
import AnalyticsPage from './AnalyticsPage';
import OtherRostersPage from './OtherRosterPage';
import SpecificUserPage from './SpecificUserPage';

// Get the Firebase config from the auto generated file.
const firebaseConfig = require('./firebase-config.json').result.sdkConfig;

// Instantiate a Firebase app.
const firebaseApp = firebase.initializeApp(firebaseConfig);
const queryClient = new QueryClient();


/**
 * The Splash Page containing the login UI.
 */
class App extends React.Component {

  handleSignOut = () => {
    firebaseApp.auth().signOut().then(() => {
      console.log("Signed out successfully");
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  }
  
  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  state = {
    isSignedIn: undefined,

  };

  /**
   * @inheritDoc
   */
  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user, user });
    });
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  /**
   * @inheritDoc
   */
  render() {

    // const token = await FirebaseAuth.instance.currentUser().getIdToken();
    let tokenManager = {}
    let name
    if (this.state.user) {
      tokenManager = this.state.user.toJSON().stsTokenManager
      console.log(this.state.user)
      name = this.state.user.displayName || this.state.user.email.split('@')[0]
    }



    return (

      <FirebaseAuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>  <div>
            {this.state.isSignedIn && <Header name={name} />}
            {this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
              <div>
                <StyledFirebaseAuth uiConfig={this.uiConfig}
                  firebaseAuth={firebaseApp.auth()} />
              </div>
            }
            {this.state.isSignedIn &&
              <div >
                <Routes>
                  {/* <Route path="/" element={<Layout />}> */}
                  <Route index element={<div>  Token: <div style={{ width: '600px', overflow: 'scroll' }}>{'Bearer ' + tokenManager.accessToken}</div>
                    <br />
                    Expires: {(new Date(tokenManager.expirationTime)).toISOString()}
                    <br />
                    <Button className="mt-2" onClick={this.handleSignOut}>Sign Out</Button> </div>} />
                  {/* <Route path="about" element={<About />} /> */}
                  <Route path="users" element={<FindUsersPage />} />
                  <Route path="patch-fr-roster" element={<PatchRyanairPage />} />
                  <Route path="analytics" element={<AnalyticsPage />} />
                  <Route path="other-rosters" element={<OtherRostersPage />} />
                  <Route path="users/:id" element={<SpecificUserPage />} />



                  {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                  <Route path="*" element={<div> No page found</div>} />

                </Routes>

              </div>
            }
          </div>
          </BrowserRouter>
        </QueryClientProvider >
      </FirebaseAuthProvider>
    );
  }
}

// Load the app in the browser.
ReactDOM.render(<App />, document.getElementById('app'));
