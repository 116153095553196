// React core.
import React, { useState } from 'react';

// Components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Container,
  Row,
  Table,
  Col,
} from 'reactstrap';

function OtherRostersPage(args) {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Row>
        <Col>
          <h2>Patch Other Rosters</h2>
        </Col>
      </Row>
    </Container>

  );
}

export default OtherRostersPage;