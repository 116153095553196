/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// React core.
import React, { useState } from 'react';





// Components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from 'reactstrap';

function Header(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (

    <Navbar expand={true} dark={true} light={true}>
      <NavbarBrand href="/">Levo</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="" navbar>
          <NavItem>
            <NavLink href="/users/">Find Users</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/analytics/">Analytics</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Rosters
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem href="/patch-fr-roster/">Patch Ryanair Roster</DropdownItem>
              <DropdownItem
                href="/other-rosters">Other Rosters</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

      </Collapse>
      <NavbarText className="ml-auto">Hello {args.name}</NavbarText>

    </Navbar>

  );
}

export default Header;