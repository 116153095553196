
// React core.
import React, { useState, useRef } from 'react';

// Components
import {
  Container,
  Row,
  Col,
  Table,
  Button,
} from 'reactstrap';

import { useQuery } from 'react-query';
import request, { gql } from 'graphql-request';

import { useFirebaseAuth } from "./FirebaseAuthContext";

const GRAPHQL_ENDPOINT = "https://api.completeaviation.co.uk/graphql"
// const GRAPHQL_ENDPOINT = "http://localhost/graphql"


const getBrokenRostersQuery = `
  query getBrokenRosters {
    brokenRosters {
      created_at
      filename
    }
  }
`;

const getBrokenRosterImageAndOcrQuery = `
query getBrokenRosterImageAndOcrText($filename: String!) {
  
  getRosterImageFile(filename:$filename) {
    base64
  }

  getRosterImageOCRResponse(filename:$filename) {
    planned
    published
    planned_row_counts
    published_row_counts
    published_md5
    planned_md5
  }
}
`;

const parseOcrText = `
  query parse_ocr($planned: String!, $published: String!, $published_row_counts: [Int!]!, $planned_row_counts: [Int!]!) {
    parseOCR(planned: $planned, published: $published, published_row_counts: $published_row_counts, planned_row_counts: $planned_row_counts) {
      planned
      published
    }
  }
`;
  
const patchOcr = `
  mutation patch_ocr($planned: String!, $published: String!, $md5_planned: String!, $md5_published: String!) {
    patchOCR(planned: $planned, published: $published, md5_planned: $md5_planned, md5_published: $md5_published) {
       success
    }
  }
`;

const uploadRosterFile = `
  mutation uploadRosterFile($base64: String!, $filename: String!) {
    uploadBrokenRosterFile(base64: $base64, filename: $filename) {
       success
    }
  }
`;

const deleteRosterFile = `
  mutation deleteFile($filename: String!) {
    deleteRosterFile(filename: $filename) {
      success
    }
  }
`;

const Base64Image = ({ base64 }) => {
  return (
    <div>
      <img src={"data:image/jpeg;base64," + base64} alt="Base64 Encoded JPEG" />
    </div>
  );
};

function PatchRyanairPage(args) {

  const [selectedRoster, setSelectedRoster] = useState(null);

  // TODO: convert into a single json state object
  const [plannedOCRResponse, setPlannedOCRResponse] = useState("");
  const [publishedOCRResponse, setPublishedOCRResponse] = useState("");

  const [plannedMd5OCRResponse, setPlannedMd5OCRResponse] = useState("");
  const [publishedMd5OCRResponse, setPublishedMd5OCRResponse] = useState("");

  const [plannedRowCountsOCRResponse, setPlannedRowCountsOCRResponse] = useState(null);
  const [publishedRowCountsOCRResponse, setPublishedRowCountsOCRResponse] = useState(null);

  // Upload file to broken list
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const inputRef = useRef(null);

  // Fetch Parsed JSON Result
  const [parsedResponseLoading, setParsedResponseLoading] = useState(false);
  const [parsedResponseError, setParsedResponseError] = useState(false);
  const [parsedResponse, setParsedResponse] = useState("");

  // Patching Action
  const [patchedResponseLoading, setPatchedResponseLoading] = useState(false);
  const [patchedResponseError, setPatchedResponseError] = useState(false);
  const [patchedResponse, setPatchedResponse] = useState("");
  const [patchedResponseSuccess, setPatchedResponseSuccess] = useState(false);

  const user = useFirebaseAuth();

  const queryVariables = {};

  let tokenManager = {}
  let enabled = false;
  if (user) {
    // console.log(user.toJSON())
    tokenManager = user.toJSON().stsTokenManager
    enabled = true
  }
  // console.log(tokenManager)
  const headers = { Authorization: `Bearer ${tokenManager.accessToken}` }

  // Get Roster List Call
  const getBrokenRosters = () => request(GRAPHQL_ENDPOINT,
    getBrokenRostersQuery,
    queryVariables,
    headers
  );

  const { 
    isLoading: brokerRostersLoading, 
    error: brokerRostersError, 
    data: brokenRosterList, 
    refetch: refetchBrokenRosters
  } = useQuery("getBrokenRosters", getBrokenRosters, { enabled });


  // Load Roster Image File & OCR parse
  const getBrokenRosterImageAndOcr = () => request(GRAPHQL_ENDPOINT,
    getBrokenRosterImageAndOcrQuery,
    { filename: selectedRoster },
    headers
  );
  const { 
    isLoading: brokenRosterLoading, 
    error: brokenRosterError,
    data: brokenRoster,
    refetch: refetchBrokenRoster
  } = useQuery(["getBrokenRosterImageAndOcrText", selectedRoster], getBrokenRosterImageAndOcr, { enabled: !!selectedRoster });

  // Set the fetched data to the textarea when it's available
  React.useEffect(() => {
    if (brokenRoster) {
      
      setPublishedOCRResponse(brokenRoster.getRosterImageOCRResponse.published);
      setPublishedMd5OCRResponse(brokenRoster.getRosterImageOCRResponse.published_md5);
      setPublishedRowCountsOCRResponse(brokenRoster.getRosterImageOCRResponse.published_row_counts);

      setPlannedOCRResponse(brokenRoster.getRosterImageOCRResponse.planned);
      setPlannedMd5OCRResponse(brokenRoster.getRosterImageOCRResponse.planned_md5);
      setPlannedRowCountsOCRResponse(brokenRoster.getRosterImageOCRResponse.planned_row_counts);
      
      setParsedResponse(null)
      setPatchedResponseSuccess(false)
    }
  }, [brokenRoster]);

  const handleFetchParsedData = async () => {
    try {
      setParsedResponseLoading(true)
      setParsedResponseError(false)
      setPatchedResponseSuccess(false)
      
      const response = await request(GRAPHQL_ENDPOINT, parseOcrText, { 
        planned: plannedOCRResponse,
        published: publishedOCRResponse,
        planned_row_counts: plannedRowCountsOCRResponse,
        published_row_counts: publishedRowCountsOCRResponse
      }, headers);

      // TODO: For the sake of time, I didn't add typing to the json as it passed through the
      // API layer, so I converted it to string to get it working asap
      const resp = {
        planned: JSON.stringify(JSON.parse(response.parseOCR.planned), null, 2),
        published: JSON.stringify(JSON.parse(response.parseOCR.published), null, 2),
      }
      setParsedResponse(resp)
      setParsedResponseLoading(false)
    } catch (error) {
      setParsedResponseError(true)
      setParsedResponseLoading(false)
      console.error('Error fetching parsed roster data:', error);
    }
  };

  const handlePatchData = async () => {
    try {

      setPatchedResponseLoading(true)
      setPatchedResponseError(false)
      setPatchedResponseSuccess(false)
      
      console.log({ 
        planned: plannedOCRResponse,
        published: publishedOCRResponse,
        planned_row_counts: plannedRowCountsOCRResponse,
        published_row_counts: publishedRowCountsOCRResponse
      })

      const response = await request(GRAPHQL_ENDPOINT, patchOcr, { 
        planned: plannedOCRResponse,
        published: publishedOCRResponse,
        md5_planned: plannedMd5OCRResponse,
        md5_published: publishedMd5OCRResponse
      }, headers);
      
      console.log(response)

      setPatchedResponse(response)
      setPatchedResponseLoading(false)
      setPatchedResponseSuccess(true)

    } catch (error) {
      
      setPatchedResponseLoading(false)
      setPatchedResponseError(true)
      
      console.error('Error patching roster ocr:', error);
    }
  };

  const handlePlannedOcrTextChange = (e) => {
    console.log('planned ocr change')
    setPlannedOCRResponse(e.target.value);
    setPatchedResponseSuccess(false)
  };
  const handlePublishedOcrTextChange = (e) => {
    console.log('published ocr change')
    setPublishedOCRResponse(e.target.value);
    setPatchedResponseSuccess(false)
  };

  const formatDateTime = (dateString) => {
    const [date, time] = dateString.split('T')
    return `${date} ${time.substring(0, 5)}Z`
  }

  const handleDelete = async (filename) => {
      try {
  
        console.log(`Deleting roster: ${filename}`)
  
        const response = await request(GRAPHQL_ENDPOINT, deleteRosterFile, { 
          filename,
        }, headers);
        
        console.log(response)
        
        if(response.deleteRosterFile?.success == true) {
          alert(`Successfully deleted ${filename}`)
        } else {
          alert(`Error: delete failed ${filename}`)
        }
        
        refetchBrokenRosters()
    } catch (e) {
      console.error(e)
    }
  }

  const uploadFile = async (file) => {
    try {
        // Get original filename without extension
        const originalName = file.name.split('.').slice(0, -1).join('.');
        
        // Get file extension
        const fileExtension = file.name.split('.').pop();

        // Generate a few random characters to add to the filename as we see dupes often
        const randomChars = Math.random().toString(36).substring(2, 7);

        // Construct the new filename
        const newFileName = `${originalName}_${randomChars}.${fileExtension}`;

        // Convert file to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const base64Data = reader.result.split(',')[1]; // To remove the "data:*/*;base64,"
          
            const response = await request(GRAPHQL_ENDPOINT, uploadRosterFile, { base64: base64Data, filename: newFileName }, headers);
            
            console.log(response)
            
            // Assuming the correct field in the response for a successful upload is `uploadFile` and not `deleteRosterFile`
            if(response.uploadBrokenRosterFile?.success == true) {
              alert(`Successfully uploaded ${newFileName}`)
            } else {
              alert(`Error: upload failed for ${newFileName}`)
            }
            
            refetchBrokenRosters()
        };
        reader.onerror = (error) => {
            console.error("Error converting file to base64 for upload", error);
            alert(`Failed to convert file to base64 for upload`)
        };
    } catch (e) {
        console.error(e);
        alert(`Failed to upload file`)
    }
  }

  console.log("Selected: " + selectedRoster)

  return (
    <Container>
      <Row>
        <Col>
          <h2>Ryanair Patching</h2>
        </Col>
      </Row>
      {/* <button disabled>Reload Broken Rosters</button> */}
      <Row>
        <Col>
          <h4>
            Broken Rosters
          </h4>
          {brokerRostersLoading && <b>Loading List..</b>}
          {brokerRostersError && <b>Error Loading List</b>}
          {!brokerRostersLoading && <Table dark>
            <tbody>
            { !brokenRosterList || brokenRosterList.brokenRosters.length == 0 ? <tr><td><p>No broken rosters</p></td></tr> :
              brokenRosterList.brokenRosters.sort((a, b) => {return new Date(b.created_at) - new Date(a.created_at)}).map(roster => <tr onClick={() => { setSelectedRoster(roster.filename); }}><td>{roster.filename} @ {formatDateTime(roster.created_at)}<Button className="float-end bg-white btn-close" onClick={(e) => {
                            e.stopPropagation(); // stop it from selecting it also
                            handleDelete(roster.filename);
                        }}/></td></tr> )}
            </tbody>
            <tfoot>
              <td>* Click a file above to load</td>
            </tfoot>
          </Table>}
        </Col>
      </Row>

      <Row>
        <Col>
          <input
            type="file"
            ref={inputRef}
            onChange={e => {
                if (e.target.files.length > 0) {
                    setSelectedFile(e.target.files[0]);
                }
            }}
          />
          <Button onClick={() => {
              uploadFile(selectedFile)
              if(inputRef.current) {  // check if ref is available
                inputRef.current.value = '';  // clear input
              }
              setSelectedFile(null)
          }} disabled={!selectedFile}>
              Confirm
          </Button>
        </Col>
      </Row>

      <br />
      <br />

      {brokenRosterLoading && <b>Loading Roster..</b>}
      {brokenRosterError && <b>Error Loading Broken Roster</b>}
      {brokenRoster && (
        <Row>
          <Col>
            <h4>Showing: {selectedRoster}</h4>
            <br />
            <Row>
              <Col>
                <Base64Image base64={brokenRoster.getRosterImageFile.base64} />
              </Col>
              <Col>
                <p>Published</p>
                <textarea className="ocr-text published" value={publishedOCRResponse} onChange={handlePublishedOcrTextChange}></textarea>
                <p>md5 - {publishedMd5OCRResponse}</p>
                <p>Planned</p>
                <textarea className="ocr-text planned" value={plannedOCRResponse} onChange={handlePlannedOcrTextChange}></textarea>
                <p>md5 - {plannedMd5OCRResponse}</p>
              </Col>
            </Row>
            
            <br />
            <Button onClick={handleFetchParsedData}>Run Parse</Button>
            <Button onClick={handlePatchData}>Save Patch</Button>
            {patchedResponseSuccess && <p>Success!</p>}
            <br />
            <br />
            <h4>Parsed Response:</h4>
            {parsedResponseLoading && <p>Loading..</p>}
            {parsedResponseError && <p>Failed to parse OCR data, please edit and try again. More information can be found by looking at the server logs</p>}
            {parsedResponse && <><Row>
              <Col>
                <h5>Published</h5>
                <textarea className="parsed-response" value={parsedResponse.published}></textarea>
              </Col>
              <Col>
                <h5>Planned</h5>
                <textarea className="parsed-response" value={parsedResponse.planned}></textarea>
              </Col>
            </Row>
            <Row>
              <Col>
              Tips to find issues:
              <ul>
                <li>Focus your time on the planned data, the issue is almost always in their because unknown codes are first seen here and the font is thinner</li>
                <li>Look for incorrect or missing dates</li>
                <li>Look for objects that have type = "unknown"</li>
                <li>In the OCR, you will lots of small mistakes, we clean up common OCR issues automatically, so likely comma instead of a full stop is not the problem.</li>
                <li>TODO ?</li>
              </ul>
              </Col>
            </Row></>}

            <br/>

          </Col>
        </Row>
      )}

    </Container>
  );

}



export default PatchRyanairPage;