
// React core.
import React, { useState } from 'react';





// Components
import {
  Container,
  Row,
  Col,
  Table,


} from 'reactstrap';



function AnalyticsPage(args) {

  // const [userCount, setUserCount] = useState(0);
  // const [activeUsers, setActiveUsers] = useState(0);
  // const [airlineData, setAirlineData] = useState([]);

  // Fetch data from server on component mount
  // useEffect(() => {
  //   // Make API calls to fetch user data
  //   // ...

  //   // Update state variables with fetched data
  //   // setUserCount(userCountFromServer);
  //   // setActiveUsers(activeUsersFromServer);
  //   // setAirlineData(airlineDataFromServer);
  // }, []);


  return (
    <Container>
      <Row>
        <Col>
          <h2>Analytics Dashboard</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <b1> Total Users</b1>
          <Table dark>
            <thead>
              <tr>
                <th>User Data</th>
              </tr>
            </thead>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table dark>
            <thead>
              <tr>
                <th>Airline Data</th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>

  );
}




export default AnalyticsPage;